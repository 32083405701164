import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostLayout from "../../../layouts/post-layout.tsx";
export const _frontmatter = {
  "title": "How to create beautiful Shapes in CSS — A Teardown of 3 Shapes, purely made in CSS",
  "path": "/posts/2019-08-05-css-shapes",
  "description": "Learn how the right combination of CSS properties can create complex and elegant shapes.",
  "date": "2019-08-05T00:00:00.000Z",
  "tags": ["CSS"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PostSeoComponent = makeShortcode("PostSeoComponent");
const PostMetaComponent = makeShortcode("PostMetaComponent");
const AlertComponent = makeShortcode("AlertComponent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PostSeoComponent mdxType="PostSeoComponent" />
    <h1>{`How to create beautiful Shapes in CSS — A Teardown of 3 Shapes, purely made in CSS`}</h1>
    <PostMetaComponent mdxType="PostMetaComponent" />
    <AlertComponent level="warning" mdxType="AlertComponent">
This article was published a long time ago. Its content may be outdated and no longer reflects the current state of technology.
    </AlertComponent>
    <p>{`Over the time I came across some really interesting shapes, purely made in CSS and I always asked myself how a combination of CSS properties can create such complex and elegant shapes.`}</p>
    <p>{`I have picked 3 shapes that we will be recreating in this article.`}</p>
    <p>{`Drawing shapes in CSS typically boils down to using a combination of `}<inlineCode parentName="p">{`width`}</inlineCode>{`, `}<inlineCode parentName="p">{`height`}</inlineCode>{`, `}<inlineCode parentName="p">{`border`}</inlineCode>{`, `}<inlineCode parentName="p">{`top`}</inlineCode>{`, `}<inlineCode parentName="p">{`right`}</inlineCode>{`, `}<inlineCode parentName="p">{`left`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom`}</inlineCode>{` and transform properties with pseudo elements like `}<inlineCode parentName="p">{`:before`}</inlineCode>{` and `}<inlineCode parentName="p">{`:after`}</inlineCode>{`.`}</p>
    <p>{`Let’s take a look at this coloured box:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f79571d175dd2dc4494baf98572306a6/48c0e/box.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVQ4y+3LMQrCQBBG4T27BBsxmMJSNFmLQFjByn6t9A6SzqQyYJNk/tkZr7Ci5T5e+5l7Xx+G6jhYG//L2ne5PV+Mdh5hhjAEkbNiIioXrZHOqwT9MoZUy0fCCSf8f/z8BfdeaVJAQbEz8Tjvs9bIdReaPLhN/OwKPa1vK/cBofxy/AxtXrcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Box",
            "title": "Box",
            "src": "/static/f79571d175dd2dc4494baf98572306a6/48c0e/box.png",
            "srcSet": ["/static/f79571d175dd2dc4494baf98572306a6/4edbd/box.png 175w", "/static/f79571d175dd2dc4494baf98572306a6/13ae7/box.png 350w", "/static/f79571d175dd2dc4494baf98572306a6/48c0e/box.png 440w"],
            "sizes": "(max-width: 440px) 100vw, 440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  width: 200px;
  height: 200px;
  
  border-top: 10px solid lightgreen;
  border-right: 10px solid mediumpurple;
  border-bottom: 10px solid lightsalmon;
  border-left: 10px solid moccasin;
}
`}</code></pre>
    <p>{`You can see that the cutting edges of the borders are diagonally to each other. Let’s remove the box’s width and height and increase all border widths:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a7397620748792a6b7c83e97569d8473/e17e5/box-full.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0klEQVQ4y5XNMQuCQBiA4e8PN0Rbgk0tDWKdDRGCNLgk6GSmWy36C2qwpQMLcnJWLzSh1LvLg3d9eCF6mkq6QqkmVrZEjgsEH4V9piLbRaMYCuwR7Av4TEVOJdHkCiX2BPyPbPBQ35Zf/N/3ZAvzPE12cde/NJSuWZKCu54t6bjxdz9KTcU+1PIigCufBOS8Ced7ZXyjSjZOgvK0zXWJ7KbhwmJ5YMlClwpDzo1Z7el/4MhPuSGzPPAl38NfyfEwRLI8DJRUD+XDHyh7PgYh2fbWG70qYHFowrQwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Box full",
            "title": "Box full",
            "src": "/static/a7397620748792a6b7c83e97569d8473/e17e5/box-full.png",
            "srcSet": ["/static/a7397620748792a6b7c83e97569d8473/4edbd/box-full.png 175w", "/static/a7397620748792a6b7c83e97569d8473/13ae7/box-full.png 350w", "/static/a7397620748792a6b7c83e97569d8473/e17e5/box-full.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  width: 0;
  height: 0;
  
  border-top: 100px solid lightgreen;
  border-right: 100px solid mediumpurple;
  border-bottom: 100px solid lightsalmon;
  border-left: 100px solid moccasin;
}
`}</code></pre>
    <p>{`All of a sudden we got 4 triangles. Awesome!`}</p>
    <p><em parentName="p">{`But what if I only want to have one triangle?`}</em></p>
    <p>{`Just set the other border colours transparent:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/35da147f07634c9a7aaabc0149f3046b/e17e5/tri.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAhklEQVQ4y+3LXQqCUBTE8dm0BYrVOpLK2oK1hPCj1yA3M2PYfSjIg/faW3g4/B8Gfuh+OMz4P7A6aRrWR4Ow2Od+VVkEYifbhvuE20jV5TXKAzv5qLmLmac8bZgtVJ0HPUx5SHlcM1/1NTxGpHvDY1zaHl7S8PCVQx4B8stD7S1Avv1SZfEErOFSc73LZ7gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Triangle",
            "title": "Triangle",
            "src": "/static/35da147f07634c9a7aaabc0149f3046b/e17e5/tri.png",
            "srcSet": ["/static/35da147f07634c9a7aaabc0149f3046b/4edbd/tri.png 175w", "/static/35da147f07634c9a7aaabc0149f3046b/13ae7/tri.png 350w", "/static/35da147f07634c9a7aaabc0149f3046b/e17e5/tri.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  width: 0;
  height: 0;
  
  border-top: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid lightsalmon;
  border-left: 100px solid transparent;
}
`}</code></pre>
    <p>{`Or add some transformations...:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "448px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6f522e28ac88541bd77b9f3c8511c228/33b38/tri-top-left.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.42857142857142%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVQoz53RWQqAMAwE0Fzb/SKuB3L5Vo9iMmK1YDFqdehfeIRMietUeU3GVcJ5KGMHACLQQho2sowwDQ9Sw/vOIsTUG8m4D6lSPKSLN5maO9ttwgveQm5DgefOMz4akvmloQu2v4JDMryz41i+S4O9u1WwlYLvod/S4r9ZAa/41IAjstKlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Triangle top left",
            "title": "Triangle top left",
            "src": "/static/6f522e28ac88541bd77b9f3c8511c228/33b38/tri-top-left.png",
            "srcSet": ["/static/6f522e28ac88541bd77b9f3c8511c228/4edbd/tri-top-left.png 175w", "/static/6f522e28ac88541bd77b9f3c8511c228/13ae7/tri-top-left.png 350w", "/static/6f522e28ac88541bd77b9f3c8511c228/33b38/tri-top-left.png 448w"],
            "sizes": "(max-width: 448px) 100vw, 448px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  width: 0;
  height: 0;
  
  border-top: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid lightsalmon;
  border-left: 100px solid transparent;
  
  transform: translateX(-100px) translateY(-100px) rotate(-45deg);
}
`}</code></pre>
    <p>{`And this is how you create a basic triangle in CSS. It certainly takes some practice and creativity to get into this kind of styling with CSS, but once you got the hang, the only limitation is yourself 💡.`}</p>
    <p>{`Now let’s create some really astonishing shapes!`}</p>
    <h2>{`The Heart ❤️`}</h2>
    <p>{`Event tho a heart looks like a complicated shape, because of all its curves, it is really no that heart(ha, got it?) to create it in CSS.`}</p>
    <p>{`We are basically adding two boxes with curved corners and transforming them into each other:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "398px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cd21d2278a1bbe128f449f8cea898d32/692d4/heart-left.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQ4y5WUyUoDQRRF69M0CTEhMaMimZwyO4Igbv0GQRDcCCL4ARJ0Y1QEF7rQOCVx48KFCCFr463X8rqaTmy6GiyKXnTXqfuG+1oYRIZhGP2ePNrDRh6LEaxn5MEOfX3yewlDvwQ/Pt6xOY/MGJPlGIpRZMexlqG3Z/4K6OGfAbZX+HQtidKktasJzAZRT1PnydSX7jDdXSPvRyUxJNWuxDEfxtIUdR51vJDH+8j5+agDVvxCGMvTOl7Iw10tbOmHUEtR1yV+QecnyPo4SVd4GH+aus76Cer3sDrD4ZXjXvzcBKoJK36Sw1bRTROFIHeoHPPkQ6z/+mDyZMJmGnR5yneXJvV8FNUkcj5sFY3Bt+KFXQZqNrz0lXI9RS/3I8pqmTZkPh9AMeLk7ZzbLWfO1jLL6BK/qrbtttFq/+m6iv/qjL1ZMuNXZC3l6lPhtJzFn7I3KnEeFQ+Hufjd1i8EuDdtvbfdh808Ki8a1Lr1mirtpFslJbu2/4GVoJQef5JfjsCdiId2GXkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Heart half",
            "title": "Heart half",
            "src": "/static/cd21d2278a1bbe128f449f8cea898d32/692d4/heart-left.png",
            "srcSet": ["/static/cd21d2278a1bbe128f449f8cea898d32/4edbd/heart-left.png 175w", "/static/cd21d2278a1bbe128f449f8cea898d32/13ae7/heart-left.png 350w", "/static/cd21d2278a1bbe128f449f8cea898d32/692d4/heart-left.png 398w"],
            "sizes": "(max-width: 398px) 100vw, 398px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.heart {
  position: relative;
  
  width: 200px;
  height: 160px;
}
.heart:before,
.heart:after {
  position: absolute;
  content: "";
  
  width: 100px;
  height: 160px;
  left: 100px;
  
  background-color: tomato;
  
  border-radius: 100px 100px 0 0;
  
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}
`}</code></pre>
    <p>{`Since he have created two pseudo elements, we can flip the other one on the Y axis to get the final shape:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "408px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f8a0cd88826fc23651772b63a6d2e605/e7c18/heart-full.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQ4y43SzUsCQRQAcP+wytWNCqtdL5Wrkoi5HYOOER4ivIQJlVuHvtRNoSI/QOiUt+oSHTpE0KWCIugUUVhv3sTMrrbqbgXDMryZH+/tvOeiiJRSSgAvTsnuCskk8WAH7255kFBK8ekByzrRkmQrjWd12miwI65cbPf2SpYTEPSA4oFwPwQEiPqwUmR3TmqgyhBwQ1hkp4pAFmfpy7PhGSbpBIz1sEuqDHH+jfpgcgCW5tgmMgTTfjOuyjDRBwszRn4XXp5D0MuiU6OdKyxCbATiUltQ9YMiYL3GMClsQEi0xx3MxDKEvERLcryZgpBDZtvFsEhS87zsatGxbCcc9JLsGn/tx3tWXmzYvkjbFRnEmyuzVXh8xH77P5il9ZD97WareLtZ8X96Q+rr7UNi+PLeb96Qec28/4NbvpS394bMZazSgilS5JNc1tmEWr3R2Fa1TWnFFl/KgSJwKYEqQcBNsqvdsgNbfLXA6oxLEBBsc9riNv853kt0zUnaYu4ppfB1XTnEj/fWc3bjb2gR8sB9UxVKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Heart full",
            "title": "Heart full",
            "src": "/static/f8a0cd88826fc23651772b63a6d2e605/e7c18/heart-full.png",
            "srcSet": ["/static/f8a0cd88826fc23651772b63a6d2e605/4edbd/heart-full.png 175w", "/static/f8a0cd88826fc23651772b63a6d2e605/13ae7/heart-full.png 350w", "/static/f8a0cd88826fc23651772b63a6d2e605/e7c18/heart-full.png 408w"],
            "sizes": "(max-width: 408px) 100vw, 408px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.heart {
  position: relative;
  
  width: 200px;
  height: 160px;
}
.heart:before,
.heart:after {
  position: absolute;
  content: "";
  
  width: 100px;
  height: 160px;
  left: 100px;
  
  background-color: tomato;
  
  border-radius: 100px 100px 0 0;
  
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}
.heart:after {
  left: 0;
  
  transform-origin: 100% 100%;
  transform: rotate(45deg);
}
`}</code></pre>
    <p>{`Important to note is that the origin of transformation (or rotation in this case) has been changed by setting transform-origin to the bottom right corner or 100% 100%, which is indicated by the black dot in the picture above.`}</p>
    <p>{`And this is how you create a heart in css, it wasn’t that complicated, right? 😃`}</p>
    <h2>{`The Diamond 💎`}</h2>
    <p>{`Like the heart, the diamond isn’t really complicated. I encourage you to take yourself 10 minutes and try to create the diamond on your own.`}</p>
    <p>{`Let’s start by creating a stretched out variant of our 4 triangles from earlier:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/54851cc7684b5f4aa3c5d99ed3ec1902/63ec5/d-square.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.28571428571429%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQklEQVQoz43Nz0vCUADA8f2pUR1SpxFlQl67OUH33jQ3AkXrEBXZNvfmiAg9dOig0A+x8OCcNvZD2qnC9xbDoiBL4Xv98KWurKOMm+cdoeDwC2ULgsezo/zF9T2FB5pqFlN2FtiAnZsFWJeFLkhlLtG+SZGB5veRZpYYm4U2BP9kccABwIac0GSWuuqBEZxJH/m6Wg98FriQnZkD2XEgQaHBRXpM5FatDIMz1hWiq0RHmlWCQ64wzO3NKmfkM3wDRp64xAMTvvvEpK8QHZGROinXXrarXrLq7fwqeTZOSCexNrPVzcU7X1jXiK4QE+FSDa9KfkieGQnJflh6jcrHG610/DEdmmJDI6YylTgqYfrPJrTk0+IbLZ9utnbDnXrFoMizhovyXPnTv6/XDtduxMqIwmWEl8VF5LePid7Kebvc+wBvQKzJdUnOwQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Diamond square",
            "title": "Diamond square",
            "src": "/static/54851cc7684b5f4aa3c5d99ed3ec1902/8c557/d-square.png",
            "srcSet": ["/static/54851cc7684b5f4aa3c5d99ed3ec1902/4edbd/d-square.png 175w", "/static/54851cc7684b5f4aa3c5d99ed3ec1902/13ae7/d-square.png 350w", "/static/54851cc7684b5f4aa3c5d99ed3ec1902/8c557/d-square.png 700w", "/static/54851cc7684b5f4aa3c5d99ed3ec1902/63ec5/d-square.png 812w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.diamond {
  width: 200px;
  height: 0;
  
  border-top: 100px solid lightgreen;
  border-right: 100px solid mediumpurple;
  border-bottom: 100px solid hotpink;
  border-left: 100px solid moccasin;
}
`}</code></pre>
    <p>{`If you look closely you can already see the top part of the diamond at the bottom of the image.`}</p>
    <p>{`Now, let’s change the colours, adjust some border widths and remove the top border, since we have no use for it anymore.`}</p>
    <p>{`You should end up with a shape like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1c92c037dea577cb23ee1eb41f341806/6a6e9/d-top.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.142857142857142%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAf0lEQVQI14WNMQqDYBhD/5t2EcQeQbCTgrM38AAF0UWwgnVx9ADuDo4iOEiS8jmLhUeml8RJEihSEusJYYPoc03YsJ7MJ60iOVlK+8F8hFfgWd3hFcxH7cc5IWc5r4g7PN4ISvi3BKVpccd5PZ+XDdmAV4u0R/L9T9qbnA1ath/n8r65ydQr7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Diamond top",
            "title": "Diamond top",
            "src": "/static/1c92c037dea577cb23ee1eb41f341806/8c557/d-top.png",
            "srcSet": ["/static/1c92c037dea577cb23ee1eb41f341806/4edbd/d-top.png 175w", "/static/1c92c037dea577cb23ee1eb41f341806/13ae7/d-top.png 350w", "/static/1c92c037dea577cb23ee1eb41f341806/8c557/d-top.png 700w", "/static/1c92c037dea577cb23ee1eb41f341806/6a6e9/d-top.png 826w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.diamond {
  position: relative;
  
  width: 200px;
  height: 0;
  /* border-top: 100px solid lightgreen; */
  border-right: 100px solid transparent;
  border-bottom: 80px solid hotpink;
  border-left: 100px solid transparent;
}
`}</code></pre>
    <p>{`Finally, we add a pseudo element, `}<inlineCode parentName="p">{`:after`}</inlineCode>{` in this case and apply the same techniques as when we created the single triangle:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/026469e6a436c829ef94671d26fc00f1/c1c45/d-full.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.71428571428571%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcUlEQVQ4y5WT30vCUBTH969mLqnlDyp66TF6jKA3+wt6atM5U4koMH0w6KGHiBXkhIjIMhCh8ntOnDucE7dlcBjb5X72/dxz79WYmcdgIv4GHd8i72DzFBtRVahS+V7mg3hMzKzxD+T7fYSDFlImDDupUiaKHR5++ZEaM5Pbx84Z9BJyDtYrSZVzkLawe8G9T0mmZldGMwuQAb9SkiXcPGtk3WHpBPnqQqRfhSpSJp0/akwkq9WtfySnLRxdq4YxszeQZMP+m8xWsGpju85vIwVDmk6NByybCzVMt6jd8zdMY2HlDftX0okE3hcudmQ+0URbhfPTh3QiTj4Qfh3OwhOeGq78OzI8KztEbW8aNoXD8vN8zpGOhIRn4ckoeQMxNGx5hoW3atwfxcOBfM2VMxyEZ6VP1OyGhaNgUhWWjxGOgoNwOTYqeU11eE44Bpa7pvi6i3QJmTK1vHnheNgPAWHvEoftODIRZpZL+zKMFPbhX47ovSDmQAomAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Diamond full",
            "title": "Diamond full",
            "src": "/static/026469e6a436c829ef94671d26fc00f1/8c557/d-full.png",
            "srcSet": ["/static/026469e6a436c829ef94671d26fc00f1/4edbd/d-full.png 175w", "/static/026469e6a436c829ef94671d26fc00f1/13ae7/d-full.png 350w", "/static/026469e6a436c829ef94671d26fc00f1/8c557/d-full.png 700w", "/static/026469e6a436c829ef94671d26fc00f1/c1c45/d-full.png 824w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.diamond {
  position: relative;
  
  width: 200px;
  height: 0;
    
  border-right: 100px solid transparent;
  border-bottom: 80px solid hotpink;
  border-left: 100px solid transparent;
}
.diamond:after {
  content: "";
  position: absolute;
  border-top: 300px solid hotpink;
  border-right: 200px solid transparent;
  border-left: 200px solid transparent;
  
  transform: translateX(-100px) translateY(80px);
}
`}</code></pre>
    <p>{`Not that the position property on the pseudo element is set to absolute to position the bottom part better under the top part of the diamond.`}</p>
    <p>{`Now you can ice out your projects with ease! 🤑💎`}</p>
    <h2>{`The Magnifying Glass 🔍`}</h2>
    <p>{`Did you ever wanted to create a magnifying glass icon in CSS by yourself, instead of using a boring svg?`}</p>
    <p>{`Yeah, probably not… But let’s create one anyway!`}</p>
    <p>{`First, create a circle with a coloured border:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "222px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fba2256015f278a8ed456f1f67167ebe/6bc44/m-glass.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.28571428571429%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB10lEQVQ4y6WU/0siURDA9691LdvS+mHXr60e2Oq6rVaXUXTkVXdCd3VFEX2Rg6sIojgO4+oHiyw7y2rN1NWnvjex7rEYQT+0w8Cb95gPM++9maGgQzAmuoFQ4/Zeuby+yeULyuMTJto5IQRj3OlPGRZpe9w9FJc3f4nxOU4Yt/PyQCDmi0x+THzbP0oj1DDcXsCkLds7BwOBGM0JVqdAs0MWNmhhg/+3nBAcTWQucp08ZZDJlS2aE+y8bHOLrlA8Pr88v5L6vLQlTSUdvMx4I4xXsvPyn5NM+4Ja/pS+rKb2LGzQ4Y86/NGl7f1/ilppAQKoA5QQnGQLY4lFxhPpGxzu90ez13k9pBY5m8szXqnXN9wfiB0cn7UAyk1SrBNFxYpKHutQI6BimP2R6vGIXa6QNPlFz1yDEwtrVmfI5g6v7xw2AR6quFjTGEMVlTw1oNzAkalkj0fsdovp0zMNLpUrHnGi2xX+MDJzX22WEBRr0EkafA3g8O95r0+yOkOz39c1OHNxxXglq1P4uvqzDqCo+DWpawlBoYx4eZrmhHB8roUxdZQ+tWmZhDd2f78N6xr9tECzQ355ulJVzcGm0jb1YKa+ylSRmC3P9zeG2ZY0OwzeN4aeAdaV0zjTUZ3SAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Magnifying glass",
            "title": "Magnifying glass",
            "src": "/static/fba2256015f278a8ed456f1f67167ebe/6bc44/m-glass.png",
            "srcSet": ["/static/fba2256015f278a8ed456f1f67167ebe/4edbd/m-glass.png 175w", "/static/fba2256015f278a8ed456f1f67167ebe/6bc44/m-glass.png 222w"],
            "sizes": "(max-width: 222px) 100vw, 222px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.magnifying-glass {
  position: relative;
  
  width: 100px;
  height: 100px; 
  
  box-sizing: border-box;
  background-color: aliceblue;
  
  border: 15px solid slategray;
  border-radius: 50%;
}
`}</code></pre>
    <p>{`And that’s how fast we’re halfway through. The only thing left todo is to create and position the handle of the magnifying glass.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "270px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4dc004b8467057a03c5443af87a1e7ea/01bf6/m-full.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.28571428571429%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwElEQVQ4y42T+1MSURTH+UObKRXRTBuRN0pKy2NREQKFSRwJ85EEE5aaTE5GzZhjE2Woo6Jo6wMUQViWZWF3723WtQ3IZjpzfzhz93zu9zz2iOC1AQBYluX9Ilk6TV1gZ+c5nAAA8F95p85EAgwhjB9i3tl53eBo12O7VG9Xoy6n9+X69y2GYW7lRfwVy7KhcKS9Z7BRbmhRo3LEoTQNt3X3N8kNEhXqfh4sEEVBox4OLq6IFaYH3QO2cf/qxt7BWTaRyn3bxZ4Fljr7hsQKo80zQ5UrdTyX9sZ2vK17oL1nwL/4sUDDEoAEDQs0JFlAQfgpuiNHHGKlKRSOXOdYCz8Zn21SGFyTcySAOYrNFNksCa5KIEuymSLDQLjwYb1Vg6rMI9kcXi0uSqUzUr29Q2f9sY+RAGZJkKOgcK5KAK/A8zzVOzTWrDStRTf5Bt3AmzsHEpW51+q5wCm8zEVXwzxPAjg6M3+3U/9qKVIDR2O7EpXZOOxLF+j8bXCWZMsQ+oLhOw97/fPLEEJGgOMJ7L7Woja7Ti4JvHK7Ml6BDm+goQtZXFmtUc4XCK3F3aJGV9ZjFQgzRbZWlst5/zQtQxytGsvWXqK64Vy3X8y9a1IYH1k92CVOQS7P3wcUuJmB4YmQWGG0jk7TXO9rf8/MVV5rcUlUZoPTt5lIEgwsA1CGkGTh8SXhnppr0/Z36Kx621gqnakZFe9txw9liKNZaZLq7a7J0Ov3awuRL95AWIO6m5XGzj6bwujkh3Kezgpli4Qajk6SQ57pNq2lUYbck+rvSvUNMoQHorHdnydJDeqSqFBO/+JGv2araIaJ7Rz43yyP+AJOr38i+Pbz1xhRJPmYQ+zsOhFzn22M1xcJ1f9raau3PYGdalBXgwxBRyYYhhHVxbF/mfAizx8dJ3WDTz1ToT9p/6fxD1HlCk1zM/sFNrrIuL2CEf0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Magnifying glass full",
            "title": "Magnifying glass full",
            "src": "/static/4dc004b8467057a03c5443af87a1e7ea/01bf6/m-full.png",
            "srcSet": ["/static/4dc004b8467057a03c5443af87a1e7ea/4edbd/m-full.png 175w", "/static/4dc004b8467057a03c5443af87a1e7ea/01bf6/m-full.png 270w"],
            "sizes": "(max-width: 270px) 100vw, 270px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.magnifying-glass {
  position: relative;
  
  width: 100px;
  height: 100px; 
  
  box-sizing: border-box;
  background-color: aliceblue;
  
  border: 15px solid slategray;
  border-radius: 50%;
}
.magnifying-glass:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  
  width: 50px;
  height: 15px;
  background-color: slategray;
  
  border-radius: 0 7.5px 7.5px 0;
  
  transform-origin: 0 50%;
  transform: translateX(100%) rotate(45deg);
}
`}</code></pre>
    <p>{`Et voilà, and it can be that easy to create a magnifying glass in CSS.`}</p>
    <h2>{`But why shouldn’t I just use SVGs?`}</h2>
    <p>{`Of course you have to spend more time creating shapes like this in CSS instead of using a SVG or an icon font, but since these shapes are purely CSS it is incredibly easy to make them interactive, like adding an hover effect:`}</p>
    <p><img parentName="p" {...{
        "src": "/2c8dcc0bd9e0de918636ad3054a5f04b/heart-gif.gif",
        "alt": "Heart gif"
      }}></img>{`
I recommend you to add a wrapper around the shape you want to style to preserve the transformations on the shape itself.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="heart-wrapper">
  <div class="heart"></div>
</div>
`}</code></pre>
    <p>{`Let’s add a scale transform for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.heart {
  position: relative;
  
  width: 200px;
  height: 160px;
}
.heart:before,
.heart:after {
  position: absolute;
  content: "";
  
  width: 100px;
  height: 160px;
  left: 100px;
  
  background-color: tomato;
  
  border-radius: 100px 100px 0 0;
  
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}
.heart:after {
  left: 0;
  
  transform-origin: 100% 100%;
  transform: rotate(45deg);
}
.heart-wrapper {
  cursor: pointer;
  
  width: 200px;
  height: 160px;
  
  transition: transform 0.3s cubic-bezier(.25, .8, .25, 1);
}
.heart-wrapper:hover {
  transform-origin: 50% 50%;
  transform: scale(1.3);
}
`}</code></pre>
    <p>{`Now it’s your turn, try and recreate some shapes or icons you like in CSS, remember: The only limitation is yourself!`}</p>
    <p>{`I hope I could show you some neat tricks with this little tutorial.`}</p>
    <p>{`Cheers, Max.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      